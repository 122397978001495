/**
 *
 * This is the Modules WP page template with the Flexible fields
 *
 */

import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import * as ACFModules from "../modules/Modules"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
//import Header from "../components/Header/Header";
import Fallback from "../modules/Fallback"
import Header from "../modules/Header/Header"
import Footer from "../modules/Footer/Footer"
//import Video from '../components/video';
//import * as heroStyle from "./hero.module.css"

const ModulePages = ({ data: { wpPage, wpMenu, wp } }) => {
  const { template, slug, uri, ancestors, seo } = wpPage
  const menuItems = wpMenu?.menuItems ? wpMenu.menuItems : false
  const {
    acfOptionsGeneralSettings: {
      themeSettings: {
        button1,
        button2,
        largeBottomText,
        fieldGroupName,
        textUnderLogo,
        address,
        sitemap,
        trackers,
      },
    },
  } = wp
  const {
    ACFmodules: { modules, pageTheme, seohd },
  } = template

  return (
    <>
      <Header data={{ button1, button2 }} slug={slug} />
      <Seo seo={seo} trackers={trackers} />
      <main className={`page ${slug} page--modular theme--${pageTheme}`}>
        <Breadcrumbs
          currentUrl={wpPage?.uri}
          currentTitle={wpPage?.title}
          ancestors={ancestors}
        />
        {seohd && <h1 className="seo-title">{seohd}</h1>}
        {modules &&
          modules.map((module, id) => {
            if (Object.keys(module).length > 0) {
              const moduleName = module.fieldGroupName.replace(
                "Template_Modular_Acfmodules_Modules_",
                ""
              )
              const Component = ACFModules[moduleName] || Fallback

              return <Component key={id} {...module} />
            }
          })}
          
      </main>
      <Footer
        data={{
          largeBottomText,
          fieldGroupName,
          textUnderLogo,
          address,
          sitemap,
        }}
        menuItems={menuItems}
      />
    </>
  )
}

export const query = graphql`
  query ($id: String!) {
    wp {
      acfOptionsGeneralSettings {
        themeSettings {
          button1 {
            buttonType
            link {
              target
              title
              url
            }
          }
          button2 {
            buttonType
            link {
              target
              title
              url
            }
          }
          largeBottomText
          fieldGroupName
          textUnderLogo
          address
          sitemap {
            label
            links {
              link {
                target
                title
                url
              }
            }
            label2
            links2 {
              link {
                target
                title
                url
              }
            }
            label3
            links3 {
              link {
                target
                title
                url
              }
            }
          }
          trackers {
            script
            noscript
          }
        }
      }
    }
    wpMenu(locations: { eq: GATSBY_FOOTER_MENU }) {
      menuItems {
        nodes {
          label
          url
        }
      }
    }
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
        schema {
          raw
        }
      }
      ancestors {
        nodes {
          ... on WpPage {
            title
            uri
            slug
          }
        }
      }
      template {
        ... on WpTemplate_Modular {
          ACFmodules {
            seohd
            pageTheme
            modules {
              ... on WpTemplate_Modular_Acfmodules_Modules_Spacer {
                fieldGroupName
                backgroundType
                heightDesktop
                heightMobile
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_FullWidthImage {
                fieldGroupName
                image {
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  caption
                }
                imageHeight
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_Hero {
                fieldGroupName
                heroType
                heading
                text
                image {
                  caption
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                link {
                  title
                  url
                  target
                }
                linkType
                videoId
                backgroundVideo
                linkText
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_FeaturedCtas {
                moduleId
                fieldGroupName
                ctas {
                  backgroundColour
                  backgroundImage {
                    localFile {
                      extension
                      publicURL
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  foregroundImage {
                    localFile {
                      extension
                      publicURL
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  link {
                    title
                    url
                    target
                  }
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_Video {
                fieldGroupName
                paddings {
                  paddingTop
                  paddingBottom
                  paddingTopMobile
                  paddingBottomMobile
                }
                backgroundType
                enableBackgroundColourSplit
                autoplay
                videoId
                moduleId
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_Calculator {
                fieldGroupName
                heading
                moduleId
                backgroundType
                paddings {
                  paddingBottom
                  paddingTop
                  paddingBottomMobile
                  paddingTopMobile
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_ColumnText {
                fieldGroupName
                paddings {
                  paddingTop
                  paddingBottom
                  paddingTopMobile
                  paddingBottomMobile
                }
                backgroundType
                headerSize
                heading
                moduleId
                textColumn1
                textColumn2
                link {
                  target
                  url
                  title
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_InteractiveMap {
                fieldGroupName
                moduleId
                paddings {
                  paddingTop
                  paddingBottom
                  paddingTopMobile
                  paddingBottomMobile
                }
                backgroundType
                text
                link {
                  target
                  title
                  url
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_GalleryGrid {
                fieldGroupName
                enableBackgroundColourSplit
                backgroundType
                moduleId
                galleryGrid {
                  mediaSize
                  mediaType
                  videoId
                  image {
                    publicUrl
                    caption
                    localFile {
                      extension
                      publicURL
                      childImageSharp {
                        gatsbyImageData(aspectRatio: 1.8)
                      }
                    }
                  }
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_IframeLoader {
                backgroundType
                fieldGroupName
                iframeUrl
                moduleId
                paddings {
                  paddingBottom
                  paddingTop
                  paddingBottomMobile
                  paddingTopMobile
                }
                minimumViewportHeightMobile
                minimumViewportHeightDesktop
                heading
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_WysiwygBlock {
                fieldGroupName
                backgroundType
                paddings {
                  paddingTop
                  paddingBottom
                  paddingTopMobile
                  paddingBottomMobile
                }
                content
                image {
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                content1
                moduleId
              }

              ... on WpTemplate_Modular_Acfmodules_Modules_WysiwygSection {
                fieldGroupName
                moduleId
                backgroundType
                paddings {
                  paddingBottom
                  paddingBottomMobile
                  paddingTopMobile
                  paddingTop
                }
                contents {
                  contentType
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  text
                  videoId
                  link {
                    url
                    title
                    target
                  }
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_ImageCarousel {
                moduleId
                backgroundType
                paddings {
                  paddingBottom
                  paddingBottomMobile
                  paddingTopMobile
                  paddingTop
                }
                arrows
                autoplay
                dots
                fieldGroupName
                moduleId
                slidesPerView
                slidesPerViewMobile
                images {
                  publicUrl
                  caption
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_ImageAndText {
                fieldGroupName
                paddings {
                  paddingTop
                  paddingBottom
                  paddingTopMobile
                  paddingBottomMobile
                }
                heading
                imagePosition
                fullWidthHeading
                backgroundType
                image {
                  caption
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                moduleId
                text
                link {
                  url
                  title
                  target
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_FullWidthCta {
                fieldGroupName
                paddings {
                  paddingTop
                  paddingBottom
                  paddingTopMobile
                  paddingBottomMobile
                }
                imagePosition
                image {
                  caption
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                text
                moduleId
                link {
                  url
                  title
                  target
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_Cta {
                fieldGroupName
                backgroundType
                moduleId
                paddings {
                  paddingTop
                  paddingBottom
                  paddingTopMobile
                  paddingBottomMobile
                }
                text
                link {
                  url
                  target
                  title
                }
                ctas {
                  enableTextOverlay
                  size
                  image {
                    caption
                    publicUrl
                    localFile {
                      extension
                      publicURL
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  link {
                    url
                    target
                    title
                  }
                  text
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_InformationBlock {
                fieldGroupName
                paddings {
                  paddingTop
                  paddingBottom
                  paddingTopMobile
                  paddingBottomMobile
                }
                heading
                infos {
                  text
                  link {
                    url
                    title
                    target
                  }
                }
                link {
                  url
                  title
                  target
                }
                moduleId
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_Table {
                fieldGroupName
                moduleId
                paddings {
                  paddingTop
                  paddingBottom
                  paddingTopMobile
                  paddingBottomMobile
                }
                backgroundType
                text
                link {
                  url
                  title
                  target
                }
                centreAlignTableHeadings
                table {
                  header
                  body
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_Accordion {
                fieldGroupName
                moduleId
                paddings {
                  paddingTop
                  paddingBottom
                  paddingTopMobile
                  paddingBottomMobile
                }
                backgroundType
                accordion {
                  imageWidth
                  text
                  heading
                  table {
                    header
                    body
                  }
                  table1 {
                    header
                    body
                  }
                  link {
                    url
                    title
                    target
                  }
                }
                text
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_GoogleCalendar {
                fieldGroupName
                moduleId
                paddings {
                  paddingBottomMobile
                  paddingBottom
                  paddingTop
                  paddingTopMobile
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_Form {
                fieldGroupName
                backgroundType
                text
                moduleId
                formId
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_Testimonials {
                fieldGroupName
                heading
                moduleId
                backgroundType
                paddings {
                  paddingTop
                  paddingBottom
                  paddingTopMobile
                  paddingBottomMobile
                }
                testimonials {
                  text
                  quotee
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_SupportBlock {
                fieldGroupName
                heading
                options {
                  optionName
                  text
                  addForm
                  formId
                }
              }
              ... on WpTemplate_Modular_Acfmodules_Modules_NewsBlock {
                fieldGroupName
                moduleId
                paddings {
                  paddingBottom
                  paddingTop
                  paddingBottomMobile
                  paddingTopMobile
                }
                link {
                  url
                  title
                  target
                }
                text
                articles {
                  ... on WpStory {
                    id
                    categories {
                      nodes {
                        name
                        slug
                      }
                    }
                    slug
                    title
                    date
                    excerpt
                    newsArticle {
                      cardType
                      category
                      cardTypeImage
                      externalUrl
                      featuredArticle
                      linkText
                      image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

/*export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      ACFmodules {
        pageModules {
          __typename
          ... on WpPage_Acfmodules_PageModules_Hero {
            fieldGroupName
            heading
            subheading
            video
          }
          ... on WpPage_Acfmodules_PageModules_TextColumn {
            heading
            paddingBottom
            paddingTop
            theme
          }
          ... on WpPage_Acfmodules_PageModules_ImageContent {
            content
            paddingTop
            paddingBottom
            moduleId
            imagePosition
          }
          ... on WpPage_Acfmodules_PageModules_2ImagesText {
            content
            paddingTop
            paddingBottom
            moduleId
            contentBelowImageRight
            contentBelowImageLeft
          }
          ... on WpPage_Acfmodules_PageModules_FullWidthImage {
            moduleId
            paddingLeft
          }
        }
      }
    }
  }
`;*/

export default ModulePages
