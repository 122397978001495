import React, { useRef, useState, useEffect } from 'react'
import Section from '../../components/Section/Section'
// import IframeResizer from 'iframe-resizer-react'
import './IframeLoader.scss'

const IframeLoader = ({ moduleId, paddings, iframeUrl, heading, minimumViewportHeightMobile, minimumViewportHeightDesktop}) => {
  const iframeRef = useRef(null);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [iFrameHeight, setIFrameHeight] = useState(0);

  useEffect(() => {
    setTimestamp(Date.now());
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.src = `${iframeUrl}?${timestamp}`;
    }
  }, [iframeUrl]);

   return iframeUrl ? (
    <Section id={moduleId} paddings={paddings} classes="iframe-loader">
      <div className="container">

        {heading && <div className="iframe-loader__heading">
          <h2 class="heading-border">{heading}</h2>
        </div>}
        {/* <IframeResizer
          forwardRef={iframeRef}
          log
          src={iframeUrl}
          style={{ width: '1px', minWidth: '100%'}}
          heightCalculationMethod="bodyScroll"
        /> */}
        <iframe ref={iframeRef}
          // onLoad={() => {
          //   setIFrameHeight(iframeRef.current.contentWindow.document.body.scrollHeight);
          // }}
          width="100%"
          height={iFrameHeight + 'px'}
          style={{"--minimumViewportHeightDesktop": minimumViewportHeightDesktop+'vw', "--minimumViewportHeightMobile": minimumViewportHeightMobile+'vw'}}
          src={iframeUrl}></iframe>
      </div>
    </Section>
   ) : '';
}

export default IframeLoader;